import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { PageLinks } from "../../common/site/page-static-links"

const SingleCta = (props) => {
  // Sticky scroll
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 750)
    })
    if (scroll) {
      document.body.classList.add("chat-with-button-scroll" , props?.className)
    } else {
      document.body.classList.remove("chat-with-button-scroll" , props?.className)
    }
  }, [scroll])
  // Sticky scroll
  return (
    <>
      {scroll ? (
        <section className={`enquire-wrapper with-single-cta`}>
            <Link to={`/${PageLinks.valuation}/`} className="button button-primary">Book a Free Valuation</Link>
        </section>
      ) : (
        ""
      )}
    </>
  )
}

export default SingleCta
