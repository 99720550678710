import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import ContentModule from "../../modules/content-render";
import "./assets/styles/_index.scss"
import ReadMore from "../ReadMore/ReadMore";

const LandingPlainSection = (props) => {
    return (
        <section className={`landing-plain-section-wrapper section-${props.index}`}>
            <Container>
                <Row className={props.layout === "center_align" ? "justify-content-center" : ""}>
                    <Col xl={7}>
                        {props.title && <h2>{props.title}</h2>}
                        {props.content.data.content &&
                            <div className="inner-section">
                                <ReadMore
                                    content={props.content.data.content}
                                    height={150}
                                    parentClassName={`.landing-plain-section-wrapper.section-${props.index}`}
                                />
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default LandingPlainSection