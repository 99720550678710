import { graphql, useStaticQuery } from "gatsby"
const useSiteConfig = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          enable_team_link
          enable_areguide_valuation_module
          newsdetail_sidebar_swapping
          single_cta_for_mobile
        }
      }
    }
  `)

  const gatsbyConfigData = data?.site?.siteMetadata

  return {
    enable_team_link: gatsbyConfigData?.enable_team_link,
    enable_areguide_valuation_module: gatsbyConfigData?.enable_areguide_valuation_module,
    newsdetail_sidebar_swapping: gatsbyConfigData?.newsdetail_sidebar_swapping,
    single_cta_for_mobile: gatsbyConfigData?.single_cta_for_mobile
  }
}

export default useSiteConfig
