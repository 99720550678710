import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import MortgageCalculator from "../PropertyCalculator/MortgageCalculator";
import { Container } from 'react-bootstrap'
import './assets/styles/_index.scss'

const MortgageCalculatorSection = () => {
    return (
        <section className='mortgage-calculator-wrapper'>
            <Container>
                <ScrollAnimation animateIn="animate__fadeIn" animateOnce delay={100} offset={10}>
                    <MortgageCalculator propertyPrice={"500000"} disablePrice={false} />
                </ScrollAnimation>
            </Container>
        </section>
    )
}

export default MortgageCalculatorSection